import {isEmpty} from 'react-redux-firebase';
import {RootState} from '../../store/store';
import {Chat, Message} from '../types/ChatTypes';

export const getMostRecentChatMsg = (state: RootState): Message | undefined => {
  const messageArray = state.firestore?.ordered?.mostRecentChatMsgQuery;
  return messageArray && messageArray.length > 0 ? messageArray[0] : undefined;
};

export const getTrainerLastMessages = (state: RootState) => {
  //@ts-expect-error works fine :)
  // TODO: fix type error
  return state.firestore.data.lastMessages;
};

export const getChats = (state: RootState): {[chatId: string]: Chat} => {
  //@ts-expect-error works fine :)
  // TODO: fix type error
  return state.firestore.data.chats;
};
export const getChatId = (
  state: RootState,
  partnerId: string,
  userId: string,
) => {
  const chats = getChats(state);
  if (isEmpty(chats)) {
    return undefined;
  }
  const selfChat = userId === partnerId;
  return Object.values(chats).find((chat) => {
    if (selfChat) {
      // Check if participantIds only contains the userId
      const participantsSet = new Set(chat.participantIds);
      return participantsSet.size === 1 && participantsSet.has(userId);
    } else {
      return (
        chat.participantIds.includes(partnerId) && chat.chatType === 'private'
      );
    }
  })?.chatId;
};

export const getUserHasUnreadMessages = (state: RootState, userId: string) => {
  const chats = getChats(state);
  if (isEmpty(chats)) {
    return false;
  }

  return Object.values(chats).some(
    (chat) =>
      chat?.lastMessage?.unseen?.includes(userId) &&
      chat.chatType === 'private',
  );
};
